@use "reset";
@use "fonts";
@use "variables";

* {
  font-variation-settings: "slnt" 0;
}

html {
  font-size: 100%;
}

body {
  font-size: var(--base-text-size);
  font-family: var(--font-family);
  line-height: var(--base-line-height);
  color: rgb(var(--foreground-rgb));
  background-color: rgb(var(--background-rgb));
  margin: var(--space-xl-2xl) auto;
  padding: 0 6vw;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 0.7rem 1rem;
}

footer {
  max-width: var(--wrap-max-width);
  padding-top: var(--space-xl-2xl);
  margin: var(--space-xl-2xl) auto;
  border-top: 1px solid rgba(var(--foreground-rgb), 0.1);
  
  p {
    margin: 0;
  }
}

a {
  color: rgb(var(--foreground-rgb));
}

h1, h2, h3, h4, h5, h6 {
  font-size: var(--base-text-size);
  font-weight: 600;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
}

p {
  margin: 1rem 0;
}

em {
  font-variation-settings: "slnt" 10;
  font-style: italic;
}

ul, ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

blockquote {
  padding: 0 var(--space-s-m);
  font-variation-settings: "slnt" 10;
  font-style: italic;
  border-left: 2px solid rgb(var(--foreground-rgb));
}

code {
  font-size: var(--text-size-sm);
}

figure.highlight,
div.highlight {
  padding: 0.5rem 1rem;
  background: rgb(var(--background-rgb));
  border: 1px solid rgba(var(--foreground-rgb), 0.25);
  border-radius: 0.75rem;
  overflow-x: scroll;
  margin-top: 1rem;
}

figure.highlight code,
div.highlight {
  font-size: var(--mono-text-size);
}


/* Classes */

.wrap {
  max-width: var(--wrap-max-width);
  margin: 0 auto;
}

.global-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.home-link {
  margin-bottom: 2rem;
}

.page-header {
  h1 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}

.metadata {
  color: rgba(var(--foreground-rgb), 0.5)
}

.footnotes {
  margin-top: 2rem;
  color: rgba(var(--foreground-rgb), 0.75)
}

.reversefootnote {
  // footnote link
  color: rgba(var(--foreground-rgb), 0.75);
  text-decoration: none;
}

.tags {
  display: flex;
  gap: 0.5rem;
}

.tag {
  color: rgba(var(--foreground-rgb), 0.5)
}


@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
